import $ from "jquery";
import Bootstrap from "bootstrap";
import PubSub from "pubsub-js";
import "aspnet/jquery-validation-unobtrusive";
import "aspnet/jquery-ajax-unobtrusive";
import "kendo-ui";
import "kendo-ui/src/js/kendo.aspnetmvc";
import "kendo-ui/js/jszip.min"
import "metismenu";
import "blockui";
import "respond";
import "liveaddress";
import "jackocnr/intl-tel-input";
import "digitalBush/jquery.maskedinput";

const globals = $ || Bootstrap || PubSub;